<template>
  <panel title="Support Contact Informaton">
    <ul>
      <li v-if="false && !customEnv.nosupptickets">
        <SupportSvg />
        <div>
          <div class="title">Support Ticket</div>
          <div class="text">Open a new support ticket</div>
        </div>
      </li>
      <li v-if="false && !customEnv.nosupptickets">
        <ChatSvg />
        <div>
          <div class="title">Live Chat</div>
          <div class="text">Chat with one of our representatives</div>
        </div>
      </li>
      <li>
        <EmailSvg />
        <div>
          <div class="title">Email</div>
          <div class="text">Contact us at <a :href="`mailto:${system.support_email}`">{{ system.support_email }}</a></div>
        </div>
      </li>
      <li>
        <PhoneSvg />
        <div>
          <div class="title">Phone</div>
          <div class="text">Contact us at <a :href="`tel:${system.support_phone}`">{{ system.support_phone }}</a></div>
        </div>
      </li>
    </ul>
  </panel>
</template>

<script>
import navigation from './navigation'

import SupportSvg from '@/assets/images/icons/plus-box.svg'
import ChatSvg from '@/assets/images/icons/chat.svg'
import EmailSvg from '@/assets/images/icons/email.svg'
import PhoneSvg from '@/assets/images/icons/phone.svg'

export default {
  components: {
    SupportSvg,
    ChatSvg,
    EmailSvg,
    PhoneSvg
  },

  computed: {
    customEnv () {
      return this.$store.state.app.customEnv
    },

    system () {
      return this.$store.state.app.system
    }
  },

  created () {
    this.$store.commit('page/setPageInfo', {
      title: 'Support',
      back: { name: 'Home' }
    })

    this.$store.commit('page/setTabs', navigation)
  }
}
</script>

<style lang="scss" scoped>
  ul {
    li {
      display: flex;
      svg {
        padding-top: 4px;
        margin-right: 10px;
      }
      .title {
        color: $link;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 5px;
      }

      .text {
        color: $text;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
      }
    }
    li + li {
      margin-top: 20px;
    }
  }
</style>
